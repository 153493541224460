<template>
    <div class="course">
        <panel class="concept" :light="light">
            <h1 class="text-center title">
                课程理念<br/>
                <small>Curriculum Concept</small>
            </h1>
            <b-container>
                <div>
                    <p>美科教育“智造教育”课程研发是基于两种国际上公认的教育理念——<span class="text-warning">通识教育</span>与<span class="text-warning">STEAM教育理念</span>，聚焦于培养孩子科学素养和人文素养，开创“智造教育”的全新模式。最终目的是培养完整的、通达有见识的人工智能时代掌舵者。
                    </p>
                    <br/>
                    <p>GE-Steam设计理念（General
                        Education-Steam）是一种动态的学习方法,通过GE-Steam学生们主动的探索现实世界的问题和挑战,在这个过程中领会到更深刻的知识和技能。</p>
                    <br/>
                    <view-more v-if="!!short" to="/course"/>
                </div>
                <img src="https://static.makerfac.com/w/concept.png" width="100%">
            </b-container>
        </panel>
        <panel light class="process" v-if="!short">
            <h1 class="text-center title">
                教学流程<br/>
                <small>Teaching process</small>
            </h1>
            <div class="content">
                <b-row>
                    <b-col md="6" sm="12" class="step">
                        <h2>Channel <small>导入</small></h2>
                        <p class="text-secondary">通过动画视频激发学生爱心，唤醒孩子注意力，吸引孩子进入到课堂场景中，引起探索的兴趣，了解此次课程主题;</p>
                    </b-col>
                    <b-col md="6" sm="12" class="step">
                        <h2>Contemplate <small>反思</small></h2>
                        <p class="text-secondary">完成闯关任务后，老师引导孩子进行讨论反思总结，巩固对知识点的理解与应用，培养孩子元认知能力、思辨能力、反思能力;
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="step">
                        <h2>Contact <small>联系</small></h2>
                        <p class="text-secondary">将知识内容联系生活，引发孩子想象建构主题，建立知识的相互关联逻辑，锻炼孩子联想能力和迁移能力;</p>
                    </b-col>
                    <b-col md="6" sm="12" class="step">
                        <h2>Continue <small>延续</small></h2>
                        <p class="text-secondary">每一个闯关练习都在前面的基础上螺旋式增加难度，不断地延续和进阶，建构的目的。培养孩子对知识的迁移应用能力和举一反三的能力;</p>
                    </b-col>
                    <b-col md="6" sm="12" class="step">
                        <h2>Construct <small>建构</small></h2>
                        <p class="text-secondary">
                            多重有趣的闯关设计，对知识点进行观察、探索、分析和动手操作，完成教学流程对新知识和新思维方法的建构与内化，提升孩子的逻辑思维和思考力。培养孩子的创新思维，同时专注、耐心、坚持的品质也能得到提升;</p>
                    </b-col>
                    <b-col md="6" sm="12" class="step">
                        <h2>Evaluate <small>效果评价</small></h2>
                        <p class="text-secondary">完成整节课学习后，评价孩子的表现，加深孩子对学习目标的整体把握和理解，锻炼孩子的总结归纳能力和表达能力;</p>
                    </b-col>
                </b-row>
                <b-row class="summary text-white">
                    <b-col lg="1" class="left text-center text-nowrap">
                        <h2 class="text-warning">5C+1E</h2>
                        <h2>教学流程</h2>
                    </b-col>
                    <b-col lg="11" class="right">
                        <p>
                            以学生为中心，教师在整个教学过程中起组织者、指导者、帮助者和促进者的作用，利用情境、协作、对话等学习环境要素充分发挥学生的主动性、积极性和首创精神，最终达到使学生有效地实现对当前所学知识的意义建构的目的。</p>
                    </b-col>
                </b-row>
            </div>
        </panel>

        <panel class="construct" v-if="!short">
            <h1 class="text-center title">
                课程体系<br/>
                <small>Curriculum Structure</small>
            </h1>
            <div class="content">

                <img src="https://static.makerfac.com/w/course.png" width="100%">
            </div>
        </panel>

        <panel class="scene" :light="!short">
            <h1 class="text-center title">
                课程场景<br/>
                <small>Curriculum Structure</small>
            </h1>
            <div class="content">
                <img src="https://static.makerfac.com/w/scene.png" width="100%">
            </div>
        </panel>
    </div>
</template>

<script>
import Panel from "@/components/Panel";
import ViewMore from "@/components/ViewMore";

export default {
    name: "index",
    components: {ViewMore, Panel},
    props: {
        short: Boolean,
        light: Boolean
    },
    metaInfo: {
        title: '课程体系-【官网】美科教育少儿编程教育-STEAM教育-编程教育-创客教育-编程竞赛-教育服务品牌-美科编程',
        meta: [
            {
                name: 'keywords',
                content: '编程,美科,教育,少儿编程,美科编程,编程竞赛,STEAM,创客教育,机器人教育,教育培训'
            },
            {
                name: 'description',
                content: '【商务咨询:business@makerfac.com】美科教育专注打造一站式教培机构少儿编程教育,STEM教育,创客教育,编程竞赛的行业解决方案,快速复制办学服务能力。'
            }
        ]
    }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.course {

    .concept {
        .title h1 {
            font-size: 4rem;
            font-weight: bold;
        }

        p {
            text-indent: 2rem;
        }
    }

    .process {
        .content {
            padding: 0 10%;

            .step {
                margin-bottom: 2rem;
                h2 {
                    font-size: 3rem;

                    small {
                        padding: 0.2rem .7rem;
                        font-size: 50%;
                        border: 2px solid $primary;
                        position: relative;
                        top: -.5rem;
                    }
                }
            }

            .summary {
                background-color: $primary;
                border-radius: 10px;
                padding: 1rem;

                .left {
                    .text-warning {
                        font-size: 2.9rem;
                    }
                }

                .right {
                    padding-top: 1rem;
                    line-height: 2.5rem;
                }

            }

        }
    }
}
</style>